import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import Link from '../Link';
import Auth from '../Auth';
import Ico from '../Ico/Ico';
import s from '../../markup/src/sass/app.scss';
import imgLogo from '../../markup/src/img/logo.svg';
import history from '../../history';

class Header extends React.Component {
  static contextTypes = {
    pathname: PropTypes.string.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.onLogOut = this.onLogOut.bind(this);
  }

  onLogOut(e) {
    e.preventDefault();
    Auth.logout();
    history.push(Auth.getSignInPage());
  }

  render() {
    const { pathname } = this.context;

    return (
      <header className={s.navbar}>
        <div className={s.container}>
          <div className={s.clearfix}>
            <Link className={s['navbar-logo']} to="/">
              <img alt="Pasco Machine Calendar" className={s['navbar-logo-img']} src={imgLogo} />Pasco Machine Calendar
            </Link>
            <ul className={s['navbar-nav']}>
              <li className={s[pathname.startsWith('/work-order') ? 'active' : null]}>
                <Link to="/work-order">Work Orders</Link>
              </li>
              <li className={s[pathname.startsWith('/job-number') ? 'active' : null]}>
                <Link to="/job-number">Job Numbers</Link>
              </li>
              <li className={s[pathname.startsWith('/assignee') ? 'active' : null]}>
                <Link to="/assignee">Assignees</Link>
              </li>
              <li className={s[pathname.startsWith('/work-center') ? 'active' : null]}>
                <Link to="/work-center">Work Centers</Link>
              </li>
              {Auth.can(Auth.RBAC_PERMISSION_VIEW_ADMIN_PANEL) && (
                <li className={s[pathname.startsWith('/admin') ? 'active' : null]}>
                  <Link to="/admin">Admin</Link>
                </li>
              )}
              {Auth.isLogged() && (
                <li className={s['cursor-pointer']}>
                  <Link role="button" onClick={this.onLogOut} tabIndex={0}>
                    <Ico name="logout" />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default withStyles(s)(Header);
