import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NotificationSystem from 'react-notification-system';
import s from '../../markup/src/sass/app.scss';
import styles from './style';

class NotificationCenter extends React.Component {
  constructor(props) {
    super(props);
    this.notificationSystem = React.createRef();

    this.onNotification = this.onNotification.bind(this);
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.addEventListener('onNotification', this.onNotification, false);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('onNotification', this.onNotification, false);
    }
  }

  onNotification(event) {
    const { message } = event.detail;
    const notificationSystem = this.notificationSystem.current;
    notificationSystem.addNotification(message);
  }

  render() {
    return <NotificationSystem className={s['notify-list']} ref={this.notificationSystem} noAnimation style={styles} />;
  }
}

export default withStyles(s)(NotificationCenter);
