import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../markup/src/sass/app.scss';
import icons from './icons';

class Ico extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
  };

  static defaultProps = {
    viewBox: '0 0 20 20',
  };

  render() {
    const cssClass = `ico-${this.props.name}`;
    return (
      <svg aria-hidden="true" role="img" version="1.1" className={`${s[cssClass]} ${s.ico}`} viewBox={this.props.viewBox}>
        {icons[this.props.name]}
      </svg>
    );
  }
}

export default withStyles(s)(Ico);
