import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../markup/src/sass/app.scss';
import Ico from '../Ico/Ico';

class ScrollToTopButton extends React.Component {
  static timer;
  static breaker = false;

  constructor(props) {
    super(props);
    this.state = {
      isOffsetExists: false,
    };

    this.scrollHandler = this.scrollHandler.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.scrollHandler);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.scrollHandler);
      clearTimeout(this.timer);
    }
  }

  scrollHandler() {
    if (this.breaker === true) {
      return;
    }

    this.breaker = true;
    this.timer = setTimeout(() => {
      this.setState({
        isOffsetExists: window.pageYOffset > 60,
      });

      this.breaker = false;
    }, 50);
  }

  render() {
    return (
      <button
        className={`${s['btn-shade']} ${s['btn-sq']} ${s['btn-fixed']} ${this.state.isOffsetExists ? '' : s.hidden}`}
        onClick={e => {
          e.preventDefault();
          scroll.scrollToTop();
        }}
      >
        <Ico name="arrow-double-up" />
      </button>
    );
  }
}

export default withStyles(s)(ScrollToTopButton);
