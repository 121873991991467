import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../markup/src/sass/app.scss';
import history from '../../history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    to: null,
    onClick: null,
    children: null,
    disabled: false,
  };

  handleClick = event => {
    if (this.props.disabled === true) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();

    if (this.props.to !== null) {
      history.push(this.props.to);
    }
  };

  render() {
    const { to, children, ...defProps } = this.props;
    let props = defProps;

    if (to !== null) {
      props = { ...props, href: to };
    } else {
      props = { ...props, role: 'button' };
    }

    props = { ...props, onClick: this.handleClick };

    if (this.props.disabled === true) {
      props.className = `${props.className} ${s['btn-disabled']}`;
    }

    return <a {...props}>{children}</a>;
  }
}

export default withStyles(s)(Link);
