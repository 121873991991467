import React from 'react';

export default {
  'add-assignee': (
    <path
      d="M12.463 12.003C13.41 12.72 14 13.729 14 14.846V16h2V8h2v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2h12v2H2v12h2v-1.154c0-1.117.59-2.126 1.537-2.843A3.998 3.998 0 0 0 9 14c1.48 0 2.771-.803 3.463-1.997zM18 2h2v2h-2v2h-2V4h-2V2h2V0h2v2zM9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
      fill="#383175"
    />
  ),
  'add-work': (
    <g fill="none" fillRule="evenodd">
      <path d="M0-1h20v20H0z" />
      <path fill="#383175" d="M0 4h12v2H0z" />
      <path fill="#383175" fillRule="nonzero" d="M16 8h2v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2h12v2H2v12h14V8z" />
      <path fill="#383175" d="M16 0h2v6h-2z" />
      <path fill="#383175" d="M20 2v2h-6V2zM4 8h2v2H4V8zm0 4h2v2H4v-2zm3-4h7v2H7V8zm0 4h7v2H7v-2z" />
    </g>
  ),
  'arrow-right': (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h16v16H-1z" />
      <path fill="#383175" d="M10.586 4L8.293 1.707A1 1 0 1 1 9.707.293L14.414 5 9.707 9.707a1 1 0 1 1-1.414-1.414L10.586 6H0V4h10.586z" />
    </g>
  ),
  'arrow-double-up': (
    <path
      d="M8.293 5.726a1.05 1.05 0 0 0 1.414 0 .893.893 0 0 0 0-1.323L5 0 .293 4.403a.893.893 0 0 0 0 1.323 1.05 1.05 0 0 0 1.414 0L5 2.646l3.293 3.08zm0 7a1.05 1.05 0 0 0 1.414 0 .893.893 0 0 0 0-1.323L5 7 .293 11.403a.893.893 0 0 0 0 1.323 1.05 1.05 0 0 0 1.414 0L5 9.646l3.293 3.08z"
      fill="#383175"
      fillRule="evenodd"
    />
  ),

  calendar: (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path stroke="#383175" strokeWidth="2" d="M1 16h16V2H1v14z" />
      <path fill="#383175" d="M1 1h16a1 1 0 0 1 1 1v3H0V2a1 1 0 0 1 1-1z" />
      <path fill="#383175" d="M2 0h4v3H2zm10 0h4v3h-4z" />
    </g>
  ),
  close: (
    <g fill="#C3C3DE">
      <path d="M11.95.636l1.414 1.414L2.05 13.364.636 11.95z" />
      <path d="M13.364 11.95l-1.414 1.414L.636 2.05 2.05.636z" />
    </g>
  ),
  completed: (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h16v16H-1z" />
      <path fill="#383175" fillRule="nonzero" d="M13 0l1.414 1.414-9.707 9.707L0 6.414 1.414 5l3.293 3.293z" />
    </g>
  ),

  drag: (
    <g fill="none" fillRule="evenodd" transform="translate(-5 -3)">
      <path d="M0 0h20v20H0z" />
      <circle cx="6.5" cy="4.5" r="1.5" fill="#383175" />
      <circle cx="13.5" cy="4.5" r="1.5" fill="#383175" />
      <circle cx="6.5" cy="10.5" r="1.5" fill="#383175" />
      <circle cx="13.5" cy="10.5" r="1.5" fill="#383175" />
      <circle cx="6.5" cy="16.5" r="1.5" fill="#383175" />
      <circle cx="13.5" cy="16.5" r="1.5" fill="#383175" />
    </g>
  ),
  edit: (
    <g fill="none" fillRule="evenodd">
      <path fill="#383175" fillRule="nonzero" d="M6.657 15.97l9.314-9.313-2.829-2.829-9.314 9.314v2.829h2.829zm.828 2H1.828v-5.656L13.142 1 18.8 6.657 7.485 17.97z" />
      <path fill="#D8D8D8" stroke="#383175" d="M14.556 10.192l-4.95-4.95.708-.706 4.95 4.95z" />
    </g>
  ),
  filter: (
    <g fill="none" fillRule="evenodd">
      <path d="M-2-3h20v20H-2z" />
      <path d="M0 1h16v2H0zm0 5h16v2H0zm0 5h16v2H0z" fill="#383175" />
      <circle cx="4" cy="12" r="1.5" fill="#FFF" stroke="#383175" />
      <circle cx="11" cy="7" r="1.5" fill="#FFF" stroke="#383175" />
      <circle cx="5" cy="2" r="1.5" fill="#FFF" stroke="#383175" />
    </g>
  ),
  'in-progress': (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h16v16H-1z" />
      <path fill="#57CC95" d="M10.586 4L8.293 1.707A1 1 0 1 1 9.707.293L14.414 5 9.707 9.707a1 1 0 1 1-1.414-1.414L10.586 6H0V4h10.586z" />
    </g>
  ),
  invoiced: (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <g fill="#57CC95" fillRule="nonzero">
        <path d="M16 2H2v11.382l1-.5 2 1 2-1 2 1 2-1 2 1 2-1 1 .5V2zM3 15.118l-3 1.5V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14.618l-3-1.5-2 1-2-1-2 1-2-1-2 1-2-1z" />
        <path d="M8.55 8.185l3.892-3.892 1.414 1.414-5.306 5.306-3.257-3.256 1.414-1.414z" />
      </g>
    </g>
  ),
  link: (
    <g transform="translate(-2.000000, -5.000000)">
      <path
        d="M8,9 L8,7 C8,5.8954305 8.8954305,5 10,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,13 C22,14.1045695 21.1045695,15 20,15 L17,15 L17,13 L20,13 L20,7 L10,7 L10,13 L13,13 L13,15 L10,15 C8.8954305,15 8,14.1045695 8,13 L8,11 L4,11 L4,17 L14,17 L14,11 L12,11 L12,9 L14,9 C15.1045695,9 16,9.8954305 16,11 L16,17 C16,18.1045695 15.1045695,19 14,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,11 C2,9.8954305 2.8954305,9 4,9 L8,9 Z"
        id="Shape"
      />
    </g>
  ),
  'load-more': (
    <g fill="none" fillRule="evenodd">
      <path d="M-3-1h20v20H-3z" />
      <path fill="#383175" fillRule="nonzero" d="M6 4.341a6 6 0 1 0 7.551 7.94l1.85.761A8.001 8.001 0 1 1 6 2.252V0l6 3-6 3V4.341z" />
    </g>
  ),
  logout: (
    <g fill="none" fillRule="evenodd">
      <path stroke="#383175" strokeWidth="2" d="M11 3.625V1H1v14h10v-2.625" />
      <path fill="#383175" d="M7 7h10v2H7z" />
      <path stroke="#383175" strokeLinecap="round" strokeWidth="2" d="M14 5l3 3-3 3" />
    </g>
  ),
  next: (
    <g fill="none" fillRule="evenodd">
      <path d="M-8-5h20v20H-8z" />
      <path fill="#383175" d="M.293 1.707A1 1 0 0 1 1.707.293L6.414 5 1.707 9.707A1 1 0 1 1 .293 8.293L3.586 5 .293 1.707z" />
    </g>
  ),
  'not-started': (
    <g fill="none" fillRule="evenodd">
      <path d="M0-6h16v16H0z" />
      <path d="M0 0h4v4H0zm6 0h4v4H6zm6 0h4v4h-4z" fill="#72727E" />
    </g>
  ),
  'on-hold': (
    <g fill="none" fillRule="evenodd">
      <path d="M-4-3h16v16H-4z" />
      <path d="M0 0h3v10H0zm5 0h3v10H5z" fill="#F99C11" />
    </g>
  ),

  prev: (
    <g fill="none" fillRule="evenodd">
      <path d="M-6-5h20v20H-6z" />
      <path fill="#383175" d="M6.121 1.707A1 1 0 0 0 4.707.293L0 5l4.707 4.707a1 1 0 1 0 1.414-1.414L2.828 5l3.293-3.293z" />
    </g>
  ),
  remove: (
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h20v20H-3z" />
      <path fill="#F2627B" d="M11.95.636l1.414 1.414L2.05 13.364.636 11.95z" />
      <path fill="#F2627B" d="M13.364 11.95l-1.414 1.414L.636 2.05 2.05.636z" />
    </g>
  ),
  search: (
    <g fill="none" fillRule="evenodd" transform="translate(-1)">
      <path fill="#383175" d="M6.768 9.904l2.828 2.828-4.95 4.95a2 2 0 1 1-2.828-2.828l4.95-4.95z" />
      <rect width="12" height="12" x="6" y="1" stroke="#383175" strokeWidth="2" rx="6" transform="matrix(0 1 1 0 5 -5)" />
    </g>
  ),

  waiting: (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path
        fill="#F99C11"
        fillRule="nonzero"
        d="M16 2H2v11.382l1-.5 2 1 2-1 2 1 2-1 2 1 2-1 1 .5V2zM3 15.118l-3 1.5V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14.618l-3-1.5-2 1-2-1-2 1-2-1-2 1-2-1z"
      />
      <path d="M4 7h2v2H4zm4 0h2v2H8zm4 0h2v2h-2z" fill="#F99C11" />
    </g>
  ),
  shipped: (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path
        fill="#59A8CA"
        fillRule="nonzero"
        d="M16 2H2v11.382l1-.5 2 1 2-1 2 1 2-1 2 1 2-1 1 .5V2zM3 15.118l-3 1.5V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14.618l-3-1.5-2 1-2-1-2 1-2-1-2 1-2-1z"
      />
      <path fill="#59A8CA" fillRule="nonzero" d="M5 7h3v4H5zM10 7h3v4h-3zM4 4h10v2H4z" />
    </g>
  ),
  'cost-analisys': (
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path
        fill="#CA5EB8"
        fillRule="nonzero"
        d="M16 2H2v11.382l1-.5 2 1 2-1 2 1 2-1 2 1 2-1 1 .5V2zM3 15.118l-3 1.5V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14.618l-3-1.5-2 1-2-1-2 1-2-1-2 1-2-1z"
      />
      <g>
        <path
          fill="#CA5EB8"
          fillRule="nonzero"
          d="M9.3 11.92v-1.085l.224-.024c.587-.061 1.031-.223 1.336-.478.289-.242.43-.55.43-.943 0-.284-.061-.505-.178-.67-.125-.174-.314-.324-.572-.45a6.632 6.632 0 0 0-1.06-.39l-.18-.052V5.361l.269.02c.28.02.556.065.826.131.175.043.34.092.497.146l.099-.272a5.032 5.032 0 0 0-.532-.152 4.944 4.944 0 0 0-.918-.114L9.3 5.11v-.86h-.14v.866l-.236.014c-.537.03-.952.17-1.251.414-.283.23-.423.546-.423.966 0 .405.125.71.376.933.274.244.724.455 1.35.626l.184.05v2.466l-.255-.005a5.41 5.41 0 0 1-1.09-.136 5.712 5.712 0 0 1-.565-.156v.315c.17.062.38.114.63.155.335.055.68.086 1.035.092l.245.005v1.065h.14zm.5.5H8.66v-1.078a7.611 7.611 0 0 1-.86-.09 3.483 3.483 0 0 1-.906-.256l-.144-.067V9.526l.356.168c.209.098.482.186.82.262.248.057.493.094.734.112v-1.57c-.602-.18-1.057-.406-1.366-.681-.363-.323-.544-.765-.544-1.307 0-.566.204-1.025.607-1.354.339-.276.774-.443 1.303-.505V3.75H9.8v.884a5.147 5.147 0 0 1 1.606.375l.22.092-.44 1.208-.24-.097A4.909 4.909 0 0 0 9.8 5.907v1.547c.374.114.694.237.96.366.328.161.583.364.758.61.183.255.272.578.272.96 0 .54-.206.989-.61 1.327-.345.288-.806.475-1.38.563v1.14zm-.64-4.68l-.31-.077c-.439-.11-.75-.247-.938-.427-.203-.194-.302-.465-.302-.796 0-.295.118-.546.346-.733.216-.178.529-.276.936-.306l.268-.02v2.36zm-.5-1.805a.88.88 0 0 0-.386.158.419.419 0 0 0-.164.347c0 .203.051.342.148.434.076.073.21.145.402.212V5.935zm.915 4.594l-.275.028v-2.36l.313.081c.464.122.788.253.982.406.224.177.335.44.335.766 0 .303-.125.56-.364.75-.224.179-.554.284-.99.329zm.68-.72a.428.428 0 0 0 .175-.359c0-.181-.05-.298-.145-.374-.09-.071-.253-.147-.485-.223V9.99c.206-.042.357-.103.454-.18z"
        />
        <path
          fill="#CB59B8"
          d="M8.91 12.17V11.1a7.467 7.467 0 0 1-1.07-.095A3.248 3.248 0 0 1 7 10.77v-.85c.227.107.517.2.87.28.353.08.7.123 1.04.13V8.31c-.66-.18-1.143-.407-1.45-.68C7.153 7.357 7 6.983 7 6.51c0-.493.172-.88.515-1.16.343-.28.808-.437 1.395-.47V4h.64v.87a4.95 4.95 0 0 1 1.76.37l-.27.74a5.157 5.157 0 0 0-1.49-.35v2.01c.44.127.807.262 1.1.405.293.143.515.32.665.53.15.21.225.482.225.815 0 .467-.173.845-.52 1.135-.347.29-.837.468-1.47.535v1.11h-.64zm0-4.75V5.65c-.36.027-.625.11-.795.25a.668.668 0 0 0-.255.54c0 .267.075.472.225.615.15.143.425.265.825.365zm.64 2.86c.393-.04.68-.132.86-.275a.677.677 0 0 0 .27-.555c0-.253-.08-.443-.24-.57-.16-.127-.457-.247-.89-.36v1.76z"
        />
      </g>
    </g>
  ),
  'pack-slip': (
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="20" />
      <path
        fill="#21A6EB"
        fillRule="nonzero"
        d="M16,9.23705556e-14 C17.1045695,9.23705556e-14 18,0.8954305 18,2 L18,16.618034 L15,15.118034 L13,16.118034 L11,15.118034 L9,16.118034 L7,15.118034 L5,16.118034 L3,15.118034 L0,16.618034 L0,2 C0,0.8954305 0.8954305,9.23705556e-14 2,9.23705556e-14 L16,9.23705556e-14 Z M16,2 L2,2 L2,13.381966 L3,12.881966 L5,13.881966 L7,12.881966 L9,13.881966 L11,12.881966 L13,13.881966 L15,12.881966 L16,13.381966 L16,2 Z M14,9 L14,11 L4,11 L4,9 L14,9 Z M14,5 L14,7 L4,7 L4,5 L14,5 Z"
      />
    </g>
  ),
  'block-settings': (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="block-settings" fill="#383175">
        <path
          d="M14.5454545,9.09090909 L16.3636364,9.09090909 L16.3636364,18.1818182 L0,18.1818182 L0,3.63636364 L10.9090909,3.63636364 L10.9090909,5.45454545 L1.81818182,5.45454545 L1.81818182,16.3636364 L14.5454545,16.3636364 L14.5454545,9.09090909 Z M15.4545455,1.06363636 L15.4545455,0 L17.2727273,0 L17.2727273,1.06363636 C17.3636364,1.09636364 17.4527273,1.13363636 17.5390909,1.17454545 L18.2918182,0.421818182 L19.5781818,1.70818182 L18.8254545,2.46090909 C18.8663636,2.54727273 18.9036364,2.63636364 18.9354545,2.72727273 L20,2.72727273 L20,4.54545455 L18.9363636,4.54545455 C18.9036364,4.63636364 18.8663636,4.72545455 18.8254545,4.81181818 L19.5781818,5.56454545 L18.2918182,6.85090909 L17.5390909,6.09818182 C17.4523168,6.13954676 17.3634033,6.17626533 17.2727273,6.20818182 L17.2727273,7.27272727 L15.4545455,7.27272727 L15.4545455,6.20909091 C15.3638511,6.17687738 15.2749372,6.13985523 15.1881818,6.09818182 L14.4354545,6.85090909 L13.1490909,5.56454545 L13.9018182,4.81181818 C13.8604532,4.7250441 13.8237347,4.63613054 13.7918182,4.54545455 L12.7272727,4.54545455 L12.7272727,2.72727273 L13.7909091,2.72727273 C13.8236364,2.63636364 13.8609091,2.54727273 13.9018182,2.46090909 L13.1490909,1.70818182 L14.4363636,0.421818182 L15.1890909,1.17454545 C15.2754545,1.13363636 15.3645455,1.09636364 15.4554545,1.06454545 L15.4545455,1.06363636 Z M16.3636364,5.45454545 C17.3677905,5.45454545 18.1818182,4.64051773 18.1818182,3.63636364 C18.1818182,2.63220955 17.3677905,1.81818182 16.3636364,1.81818182 C15.3594823,1.81818182 14.5454545,2.63220955 14.5454545,3.63636364 C14.5454545,4.64051773 15.3594823,5.45454545 16.3636364,5.45454545 Z"
          id="Shape"
        />
        <path
          d="M4.54545455,10 L6.36363636,10 L6.36363636,11.8181818 L4.54545455,11.8181818 L4.54545455,10 Z M7.27272727,10 L9.09090909,10 L9.09090909,11.8181818 L7.27272727,11.8181818 L7.27272727,10 Z M10,10 L11.8181818,10 L11.8181818,11.8181818 L10,11.8181818 L10,10 Z"
          id="Shape"
        />
      </g>
    </g>
  ),
  lock: (
    <g fill="none" fillRule="nonzero">
      <g stroke="#383175" strokeWidth="2">
        <path d="M3 9h8V5a4 4 0 1 0-8 0v4z" />
        <rect width="12" height="10" x="1" y="9" rx="1" />
      </g>
      <g fill="#383175">
        <path d="M6 13h2v3H6z" />
        <path d="M5 12h4v2H5z" />
      </g>
    </g>
  ),
  show: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="show" fill="#D2D2D8" fillRule="nonzero">
        <path
          d="M9,12 C5.696,12 2.748,10.157 0.187,6.582 C-0.06208162,6.23397529 -0.06208162,5.76602471 0.187,5.418 C2.748,1.843 5.697,0 9,0 C12.304,0 15.252,1.843 17.813,5.418 C18.0620816,5.76602471 18.0620816,6.23397529 17.813,6.582 C15.252,10.157 12.303,12 9,12 Z M9,10 C11.42,10 13.662,8.697 15.753,6 C13.662,3.303 11.42,2 9,2 C6.58,2 4.338,3.303 2.247,6 C4.338,8.697 6.58,10 9,10 Z"
          id="Shape"
        />
        <path
          d="M9,9 C7.34314575,9 6,7.65685425 6,6 C6,4.34314575 7.34314575,3 9,3 C10.6568542,3 12,4.34314575 12,6 C12,7.65685425 10.6568542,9 9,9 Z M8,6 C8.55228475,6 9,5.55228475 9,5 C9,4.44771525 8.55228475,4 8,4 C7.44771525,4 7,4.44771525 7,5 C7,5.55228475 7.44771525,6 8,6 Z"
          id="Shape"
        />
      </g>
    </g>
  ),
  hide: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="hide" fill="#383175" fillRule="nonzero">
        <path
          d="M9,15 C5.696,15 2.748,13.157 0.187,9.582 C-0.06208162,9.23397529 -0.06208162,8.76602471 0.187,8.418 C2.748,4.843 5.697,3 9,3 C12.304,3 15.252,4.843 17.813,8.418 C18.0620816,8.76602471 18.0620816,9.23397529 17.813,9.582 C15.252,13.157 12.303,15 9,15 Z M9,13 C11.42,13 13.662,11.697 15.753,9 C13.662,6.303 11.42,5 9,5 C6.58,5 4.338,6.303 2.247,9 C4.338,11.697 6.58,13 9,13 Z"
          id="Shape"
        />
        <path
          d="M7.283,11.46 L11.56,7.435 C12.3011956,8.64657563 12.0911991,10.2118881 11.0568782,11.1852671 C10.0225572,12.158646 8.44738978,12.273316 7.283,11.46 Z M6.113,9.815 C5.81029874,8.7384257 6.13111211,7.58283475 6.94547294,6.8163775 C7.75983377,6.04992025 8.93272977,5.79966709 9.989,6.167 L6.112,9.815 L6.113,9.815 Z"
          id="Shape"
        />
        <polygon id="Shape" points="1.685 17.728 0.315 16.272 17.315 0.272 18.685 1.728" />
      </g>
    </g>
  ),
  'complete-all': (
    <path d="M10 0h12v2H10zM10 4h10v2H10zM10 10h12v2H10zM10 14h10v2H10zM2.86 13.19L6.052 10 7.5 11.363 2.86 16 0 13.142l1.448-1.363zM2.86 3.19L6.052 0 7.5 1.363 2.86 6 0 3.142l1.448-1.363z" />
  ),
  reload: (
    <g fill="#383175" fillRule="nonzero">
      <path d="M8.853 1l.032 2a7 7 0 1 0 7.116 7h2a9 9 0 1 1-9.148-9zM8 3.07A7 7 0 1 0 16 10h2A9 9 0 1 1 8 1.053V0l4 2-4 2v-.93z" />
      <path d="M10 8.268a2 2 0 0 1-1.518 3.664l-2.105 2.106h-1.34l-.08-1.41 2.11-2.11A2.003 2.003 0 0 1 8 8.268V6l1-1 1 1v2.268zM9 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </g>
  ),
  'note-view': (
    <g>
      <path d="M1,0 L13,0 C13.5522847,3.38176876e-17 14,0.44771525 14,1 L14,15 C14,15.5522847 13.5522847,16 13,16 L1,16 C0.44771525,16 6.76353751e-17,15.5522847 0,15 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M2,2 L2,14 L12,14 L12,2 L2,2 Z M4,5 L10,5 L10,7 L4,7 L4,5 Z M4,9 L10,9 L10,11 L4,11 L4,9 Z" />
    </g>
  ),
  'note-add': (
    <g>
      <path d="M12,7.584 C12.6307844,7.85929672 13.3117585,8.00093934 14,8 L14,16 C14,16.5522847 13.5522847,17 13,17 L1,17 C0.44771525,17 6.76353751e-17,16.5522847 0,16 L0,2 C-6.76353751e-17,1.44771525 0.44771525,1 1,1 L9.416,1 C9.14070328,1.6307844 8.99906066,2.3117585 9,3 L2,3 L2,15 L12,15 L12,7.584 Z M4,7 L10,7 L10,9 L4,9 L4,7 Z M4,11 L10,11 L10,13 L4,13 L4,11 Z M15,2 L16,2 C16.5522847,2 17,2.44771525 17,3 C17,3.55228475 16.5522847,4 16,4 L15,4 L15,5 C15,5.55228475 14.5522847,6 14,6 C13.4477153,6 13,5.55228475 13,5 L13,4 L12,4 C11.4477153,4 11,3.55228475 11,3 C11,2.44771525 11.4477153,2 12,2 L13,2 L13,1 C13,0.44771525 13.4477153,1.01453063e-16 14,0 C14.5522847,-1.01453063e-16 15,0.44771525 15,1 L15,2 Z" />
    </g>
  ),
  'note-add-lg': (
    <g>
      <path d="M13 4H2v20h16V9.917a6.04 6.04 0 0 0 2 0V25a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12.341A5.99 5.99 0 0 0 13 4z" />
      <path d="M4 18h12v2H4zM4 14h12v2H4zM4 10h8v2H4zM20 3h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0V5h-2a1 1 0 0 1 0-2h2V1a1 1 0 0 1 2 0v2z" />
    </g>
  ),
  'note-success': (
    <g fill="#57cc95">
      <path d="M7.892 4H2.81v12h10V8.917a6.04 6.04 0 0 0 2 0V17a1 1 0 0 1-1 1h-12a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h6.083a6.04 6.04 0 0 0 0 2zM17.916.925l.946.99a.5.5 0 0 1-.034.722L13.809 7l-3.603-2.946a.5.5 0 0 1-.063-.713l.897-1.045a.5.5 0 0 1 .701-.058l2.119 1.778L17.215.903a.5.5 0 0 1 .701.022zM4.81 12h6v2h-6v-2zm0-4h6v2h-6V8z" />
    </g>
  ),
  'note-success-lg': (
    <g fill="#57CC95">
      <path
        d="M13,2 L2,2 L2,22 L18,22 L18,7.917 C18.6620344,8.0281422 19.3379656,8.0281422 20,7.917 L20,23 C20,23.5522847 19.5522847,24 19,24 L1,24 C0.44771525,24 6.76353751e-17,23.5522847 0,23 L0,1 C-6.76353751e-17,0.44771525 0.44771525,0 1,0 L13.341,0 C13.1144362,0.642426773 12.9991157,1.31879331 13,2 Z"
        id="Shape"
      />
      <path d="M4,16 L16,16 L16,18 L4,18 L4,16 Z M4,12 L16,12 L16,14 L4,14 L4,12 Z M4,8 L12,8 L12,10 L4,10 L4,8 Z" id="Shape" fillRule="nonzero" />
      <path
        d="M22.894,0.155 L23.839,1.145 C23.933239,1.243735 23.9830181,1.37670393 23.9767861,1.51305186 C23.9705541,1.64939978 23.9088529,1.77727336 23.806,1.867 L18.787,6.23 L15.184,3.284 C15.0796429,3.19875634 15.0141093,3.07500053 15.0022494,2.940776 C14.9903894,2.80655147 15.0332072,2.67322184 15.121,2.571 L16.018,1.526 C16.1961804,1.31843383 16.5080873,1.29259011 16.718,1.468 L18.838,3.246 L22.192,0.133 C22.392627,-0.0529154848 22.705408,-0.0431132308 22.894,0.155 Z"
        id="Shape"
        fillRule="nonzero"
      />
    </g>
  ),
  'material-available': (
    <g fill="#57CC95" fillRule="nonzero">
      <path d="M3 10v2H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2h-2V2H2v8h1z" />
      <path d="M6 6v8h8V6H6zM5 4h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
      <path d="M14.157 7l.843.838L9.807 13 7 10.21l.843-.839 1.964 1.952z" />
    </g>
  ),
  'material-wait': (
    <g fill="#f2627b" fillRule="nonzero">
      <path d="M3 10v2H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2h-2V2H2v8h1z" />
      <path d="M6 6v8h8V6H6zM5 4h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
      <path d="M11.621 7l.707.707-4.62 4.621L7 11.621 11.621 7zm1 2l.707.707-2.62 2.621-.708-.707L12.621 9zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </g>
  ),
  'calendar-view': (
    <g id="Pasco-WO-List-All" transform="translate(-363.000000, -114.000000)">
      <path d="M376,129 L376,127 L369,127 L369,137 L367,137 L367,127 L364,127 L364,121 L367,121 L367,119 L369,119 L369,121 L376,121 L376,119 L378,119 L378,121 L380,121 L380,127 L378,127 L378,129 L386,129 L386,119 L388,119 L388,129 L391,129 L391,135 L388,135 L388,137 L386,137 L386,135 L378,135 L378,137 L376,137 L376,135 L373,135 L373,129 L376,129 Z M363,114 L371,114 L371,117 L363,117 L363,114 Z M373,114 L381,114 L381,117 L373,117 L373,114 Z M383,114 L391,114 L391,117 L383,117 L383,114 Z M366,123 L366,125 L378,125 L378,123 L366,123 Z M375,131 L375,133 L389,133 L389,131 L375,131 Z" />
    </g>
  ),
  'list-view': (
    <g id="Pasco-WO-List-All" transform="translate(-173.000000, -115.000000)">
      <path d="M173,122 L179,122 L179,128 L173,128 L173,122 Z M175,124 L175,126 L177,126 L177,124 L175,124 Z M182,122 L200,122 L200,124 L182,124 L182,122 Z M182,132 L200,132 L200,134 L182,134 L182,132 Z M182,126 L192,126 L192,128 L182,128 L182,126 Z M182,136 L192,136 L192,138 L182,138 L182,136 Z M173,132 L179,132 L179,138 L173,138 L173,132 Z M175,134 L175,136 L177,136 L177,134 L175,134 Z M173,115 L180,115 L180,118 L173,118 L173,115 Z M183,115 L190,115 L190,118 L183,118 L183,115 Z M193,115 L200,115 L200,118 L193,118 L193,115 Z" />
    </g>
  ),
  overdue: (
    <g id="Pasco-WO-List-All" transform="translate(-788.000000, -488.000000)" fillRule="nonzero">
      <path d="M799.341411,494 L790,494 L790,504 L804,504 L804,497.917042 C804.325208,497.971601 804.659288,498 805,498 C805.340712,498 805.674792,497.971601 806,497.917042 L806,505 C806,505.552285 805.552285,506 805,506 L789,506 C788.447715,506 788,505.552285 788,505 L788,492 L788,490 L799.341411,490 C799.120308,490.625558 799,491.298729 799,492 C799,492.701271 799.120308,493.374442 799.341411,494 Z M792,496 L794,496 L794,498 L792,498 L792,496 Z M792,500 L794,500 L794,502 L792,502 L792,500 Z M795,496 L800,496 L800,498 L795,498 L795,496 Z M795,500 L802,500 L802,502 L795,502 L795,500 Z M804,494 L806,494 L806,496 L804,496 L804,494 Z M804,488 L806,488 L806,493 L804,493 L804,488 Z" />
    </g>
  ),
};
