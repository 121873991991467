import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../markup/src/sass/app.scss';
import App from '../App';

class Footer extends React.Component {
  render() {
    return (
      <footer className={s.footer}>
        <div className={s.container}>
          <p className={`${s['text-xs']} ${s['mb-0']} ${s['text-right']} ${s['text-gray']}`}>{App.getCopyright()}</p>
        </div>
      </footer>
    );
  }
}

export default withStyles(s)(Footer);
