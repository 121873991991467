import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NotificationCenter from '../NotificationCenter';
import ScrollToTopButton from '../ScrollToTopButton/ScrollToTopButton';

import Header from '../Header';
import Footer from '../Footer';
import s from '../../markup/src/sass/app.scss';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
  };

  static defaultProps = {
    showHeader: true,
    showFooter: true,
  };

  render() {
    return (
      <div>
        {this.props.showHeader && <Header />}
        <ScrollToTopButton />
        {this.props.children}
        {this.props.showFooter && <Footer />}
        <NotificationCenter />
      </div>
    );
  }
}

export default withStyles(s)(Layout);
